<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">
            {{
              currentRouteName == "hrd-behavior-evaluation-update"
                ? "Edit Record"
                : "Add Record"
            }}
          </h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-md-7">
                  <div class="row mb-3">
                    <label for="name" class="col-md-3 form-label">
                      Program Name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <v-select
                        @option:selected="getSelectedProgram"
                        v-model="selectedProgram"
                        label="name"
                        :disabled="
                          currentRouteName == 'hrd-behavior-evaluation-update'
                        "
                        :options="programs"
                        :selectable="(options) => isSelectableProgram(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedProgram.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedProgram.$errors,
                          value: 'Program Name',
                        }"
                      ></v-errors>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Batch
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <v-select
                        @option:selected="getSelectedBatch"
                        v-model="selectedBatch"
                        label="batch_no"
                        :disabled="
                          currentRouteName == 'hrd-behavior-evaluation-update'
                        "
                        :options="batch"
                        :selectable="(options) => isSelectableBatch(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedBatch.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedBatch.$errors,
                          value: 'Batch',
                        }"
                      ></v-errors>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Employee name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <v-select
                        @option:selected="getSelectedEmployee"
                        v-model="selectedEmployee"
                        label="employee_name"
                        :disabled="
                          currentRouteName == 'hrd-behavior-evaluation-update'
                        "
                        :options="employeeLists"
                        :clearable="false"
                        :selectable="(option) => isSelectable(option)"
                        :class="{
                          'p-invalid':
                            v$.selectedEmployee.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedEmployee.$errors,
                          value: 'Employee Name',
                        }"
                      ></v-errors>
                    </div>
                  </div>
                </div>

                <div class="row col-md-5">
                  <div class="row mb-3">
                    <label for="short_name" class="col-md-4 form-label">
                      Employee ID
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="behavior.employee_id"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="date" class="col-md-4 form-label">
                      Joined Date
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="behavior.join_date"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="progress mt-3"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <div class="row g-3 mt-3">
                <div class="col-sm-4">
                  <h4 class="mb-3">Criteria</h4>
                </div>
                <div class="col-sm-4">
                  <h4 class="mb-3">Scores:*</h4>
                </div>
              </div>

              <div
                class="row g-3"
                v-for="(assessor, index) in assessors"
                :key="index"
              >
                <div class="col-sm-4">
                  <p class="mb-3 pt-2 pl-2">
                    {{
                      assessor.assessor_employee_name
                        ? assessor.assessor_employee_name
                        : assessor.employee_name
                    }}
                  </p>
                </div>
                <div class="col-sm-4">
                  <div class="mt-2">
                    <input
                      type="text"
                      v-if="assessor.total_score"
                      class="form-control"
                      v-model="assessor.total_score"
                      disabled
                    />
                    <input
                      type="text"
                      v-else
                      class="form-control"
                      value="0"
                      disabled
                    />
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="mt-2">
                    <input
                      type="text"
                      v-if="assessor.hrd_score"
                      class="form-control"
                      v-model="assessor.hrd_score"
                      disabled
                    />
                    <input
                      type="text"
                      v-else
                      class="form-control"
                      value="0"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div class="row g-3">
                <div class="col-sm-4">Total Score</div>
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control mt-2"
                    v-model="assessorTotalScore"
                    disabled
                  />
                </div>
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control mt-2"
                    v-model="hrdTotalScore"
                    disabled
                  />
                </div>
              </div>

              <div class="row g-3">
                <div class="col-sm-4">Average Score</div>
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control mt-2"
                    v-model="averageScore"
                    disabled
                  />
                </div>
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control mt-2"
                    v-model="hrdAverageScore"
                    disabled
                  />
                </div>
              </div>

              <div class="row g-3">
                <div class="col-sm-4">Result</div>
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control mt-2"
                    v-model="percentage"
                    disabled
                  />
                  <small class="text-success" v-if="weight != 0"
                    >Calculate weight - {{ weight }}</small
                  >
                </div>
                <div class="col-sm-4">
                  <p class="checkClick" @click="showCheck()">Check</p>
                </div>
              </div>

              <div
                class="row"
                v-if="
                  hasPermissions([
                    'hrd-behaviour-evaluation-create',
                    'hrd-behaviour-evaluation-edit',
                  ])
                "
              >
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'hrd-behavior-evaluation' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      v-if="percentage == 0"
                      style="cursor: not-allowed"
                      data-bs-toggle="tooltip"
                      title="You need to check your weight first."
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                    >
                      <span>Save</span>
                    </button>
                    <button
                      v-else
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="
                        currentRouteName === 'hrd-behavior-evaluation-update'
                          ? updateBehaviorEvaluation()
                          : createBehaviorEvaluation()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  mixins: [validationErrors, userHasPermissions],
  data() {
    return {
      programs: this.$store.getters["odoo/getAllPrograms"],
      batch: [],
      employeeLists: [],
      assessors: [],
      existedEmployees: [],
      assessorTotalScore: 0,
      hrdTotalScore: 0,
      averageScore: 0,
      assessor_length: 0,
      questionCount: 0,
      hrdAverageScore: 0,
      weight: 0,
      percentage: 0,
      behavior_assessment_detail_id: null,
      behavior: {
        type: "ORI",
        program_id: "",
        program_name: "",
        program_short_name: "",
        batch_id: "",
        batch_no: "",
        join_date: "",
        available: "",
      },
      selectedProgram: null,
      selectedBatch: null,
      selectedEmployee: null,
      loading: false,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
       
    };
  },
  validations() {
    return {
      selectedProgram: { required },
      selectedBatch: { required },
      selectedEmployee: { required },
      behavior: {
        program_id: { required },
        program_name: { required },
        program_short_name: { required },
        batch_id: { required },
        batch_no: { required },
        join_date: { required },
        available: { required },
      },
    };
  },
  methods: {
    isSelectableProgram(options) {
      if (!this.selectedProgram || !options) return true;
      return this.selectedProgram.id != options.id;
    },
    isSelectableBatch(options) {
      if (!this.selectedBatch || !options) return true;
      return this.selectedBatch.id != options.id;
    },
    showCheck() {
      this.percentage =
        this.questionCount != 0
          ? Math.round(
              (this.averageScore / this.questionCount) *
                (this.weight / 100) *
                100
            )
          : 0;
    },
    isSelectable(options) {
      if (this.selectedEmployee) {
        const empExist = this.existedEmployees.some(
          (item) => item.employee_id == this.selectedEmployee.employee_id
        );
        // If the batch doesn't exist, add it to existedEmployees
        if (!empExist) {
          this.existedEmployees = this.existedEmployees.filter(
            (item) => !item.is_selected
          );
          this.existedEmployees.push({
            program_id: this.selectedBatch.program.id,
            batch_id: this.selectedBatch.id,
            employee_id: this.selectedEmployee.employee_id,
            is_selected: true,
          });
        }
      }
      // Now, check if the options.id exists in existedEmployees
      return !this.existedEmployees.some(
        (item) =>
          item.program_id === this.behavior.program_id &&
          item.batch_id == this.behavior.batch_id &&
          item.employee_id == options.employee_id
      );
    },

    getSelectedProgram() {
      if (this.selectedProgram) {
        this.selectedBatch = null;
        this.clearData();
        this.behavior.program_id = this.selectedProgram.id;
        this.behavior.program_name = this.selectedProgram.name;
        this.behavior.program_short_name = this.selectedProgram.short_name;
        this.getBatchByProgram(this.behavior.program_id);
      }
    },
    getSelectedBatch() {
      if (this.selectedBatch) {
        this.clearData();
        this.behavior.batch_id = this.selectedBatch.id;
        this.behavior.batch_no = this.selectedBatch.batch_no;
        this.behavior.available = this.selectedBatch.available;
        this.getEmployeeByBatch(this.behavior.batch_id);
      }
    },
    getSelectedEmployee() {
      if (this.selectedEmployee) {
        this.percentage = 0;
        this.weight = 0;
        this.behavior.employee_id = this.selectedEmployee.employee_id;
        this.behavior.employee_name = this.selectedEmployee.employee_name;
        this.behavior.join_date = this.selectedEmployee.join_date;
        console.log(this.behavior.employee_id);
        this.getAssessorsByEmployee();
      }
    },
    clearData() {
      this.selectedEmployee = null;
      this.assessors = [];
      this.assessorTotalScore = 0;
      this.hrdTotalScore = 0;
      this.assessor_length = 0;
      this.averageScore = 0;
      this.hrdAverageScore = 0;
      this.percentage = 0;
    },

    async getBatchByProgram(program_id) {
      this.loading = true;
      this.$Progress.start();
      await axios
      .get(`${this.baseUrl}admin/v1/program-batches/list/?program_id=${program_id}`)
        .then((response) => {
          this.batch = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });
    },
    async getEmployeeByBatch(batch_id) {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/program-batch/employees/list/${batch_id}`)
        .then((response) => {

          this.employeeLists = response.data.data.employee_ids.map((item) => {
            if(this.behavior?.employee_id == item.employee.emp_id){
              this.behavior.join_date = item.join_date;
            }
              return {
                  employee: item.employee,
                  employee_id: item.employee.emp_id,
                  employee_name: item.employee.name,
                  join_date: item.join_date,
              }
          });

          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Employee!");
        });
    },
    async getAssessorsByEmployee() {
      this.loading = true;
      this.$Progress.start();
      this.assessors = [];
      await axios
        .get(
          `${this.baseUrl}admin/v2/behavior-assessor?program_id=${this.behavior.program_id}&batch_id=${this.behavior.batch_id}&emp_id=${this.selectedEmployee.employee_id}`
        )
        .then((response) => {
          let scores = 0;
          let hscores = 0;
          let employeeIds = [];
          response.data.data.hrd_behavior_assessments.forEach(
            (item) => {
              scores += item?.score;
              if (!employeeIds.includes(item.assessor_employee_id)) {
                let eachTotalScore = 0;
                hscores += item.hrd_score;
                this.assessor_length += 1;
                response.data.data.hrd_behavior_assessments.forEach(
                  (element) => {
                    if (
                      element.assessor_employee_id == item.assessor_employee_id
                    ) {
                      eachTotalScore += element.score;
                    }
                  }
                );
                item.total_score = eachTotalScore;
                employeeIds.push(item.assessor_employee_id);
                this.assessors.push(item);
              }
            }
          );
          this.assessorTotalScore = scores;
          this.hrdTotalScore = hscores;
          this.hrdAverageScore = this.hrdTotalScore / this.assessor_length;

          this.behavior_assessment_detail_id =
            response.data.data.behavior_assessment_detail_id;

          this.questionCount = response.data.data.questionCount * 5;
          this.weight = response.data.data.criteria_point;
          this.averageScore =
            this.questionCount != 0
              ? Math.round(this.assessorTotalScore / this.assessors.length)
              : 0;

          if (this.currentRouteName == "hrd-behavior-evaluation-update")
            this.showCheck();
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
          this.$Progress.fail();
        });
    },
    async createBehaviorEvaluation() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.isLoading = true;

      const toSendData = {
        behavior_assessment_detail_id: this.behavior_assessment_detail_id,
        total_score: this.assessorTotalScore,
        average_score: this.averageScore,
        hrd_average_score: this.hrdAverageScore,
        percentage: this.percentage,
      };
      await axios
        .post(`${this.baseUrl}admin/v2/hrd-behavior-evaluation`, toSendData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          this.toast.success("Successfully Behavior Evaluation Created.");
          this.$router.push({ name: "hrd-behavior-evaluation" });
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response;
          // console.log(error.response);
          this.toast.error(error?.response?.data?.errors?.message ?? "Something went wrong.");
          this.isLoading = false;
        });
    },
    async getBehaviorEvaluationById() {
      this.loading = true;
      await axios
        .get(
          `${this.baseUrl}admin/v2/hrd-behavior-evaluation/${this.$route.params.id}`
        )
        .then(async (response) => {
          const data = response.data.data;

          this.selectedProgram = {
            id: data.program_id,
            name: data.program_name,
          };
          this.selectedBatch = {
            batch_id: data.batch_id,
            batch_no: data.batch_no,
          };
          this.selectedEmployee = {
            employee_id: data.employee_id,
            employee_name: data.employee_name,
            
          };
          this.behavior = data;

          await this.getBatchByProgram(this.selectedProgram.id);
          await this.getEmployeeByBatch(this.selectedBatch.batch_id);
          await this.getAssessorsByEmployee();
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
        });
      // this.loading = false;
    },
    async updateBehaviorEvaluation() {
      // const isFormCorrect = await this.v$.$validate();
      // console.log(isFormCorrect )
      // if (!isFormCorrect) return;

      this.isLoading = true;

      const toSendData = {
        behavior_assessment_detail_id: this.behavior_assessment_detail_id,
        total_score: this.assessorTotalScore,
        average_score: this.averageScore,
        hrd_average_score: this.hrdAverageScore,
        percentage: this.percentage,
      };

      await axios
        .put(
          `${this.baseUrl}admin/v2/hrd-behavior-evaluation/${this.$route.params.id}`,
          toSendData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          this.toast.success("Successfully Behavior Evaluation Updated.");
          this.$router.push({ name: "hrd-behavior-evaluation" });
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
          this.isLoading = false;
        });
    },
    async getExistedEmployee() {
      this.existedEmployees = [];
    //   axios
    //     .get(
    //       `${this.baseUrl}admin/v2/hrdAssessment/hrd-behavior-evaluation-getExistedEmployee`
    //     )
    //     .then((response) => {
    //       this.existedEmployees = response.data.data;
    //       // console.log(this.existedEmployees);
    //     })
    //     .catch((error) => {
    //       this.error = error.response;
    //       this.toast.error("Something went wrong.");
    //     });
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  async created() {
    if (this.currentRouteName == "hrd-behavior-evaluation-update") {
      await this.getBehaviorEvaluationById();
    }
    await this.getExistedEmployee();
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
.custom-v-select .vs__dropdown-toggle .vs__open-indicator::before {
  display: none;
}
.selectScore {
  padding: 0.45rem 0.9rem;
}
.checkClick {
  cursor: pointer;
  font-weight: bold;
  margin-top: 13px;
  user-select: none;
  font-size: 1.1em;
}
.checkClick:hover {
  font-weight: 300;
}
</style>
